import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

import TopbarUser from "./topbarUser";
import { superadminDashboard } from "../Sidebar/options";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import { getMenuItemData } from "../../helpers/utility";
import { loginUserType } from "../../helpers/constants";
import logo from "../../image/componyLogowithBg.svg";
const { Header } = Layout;
const customizedTheme = themes[themeConfig.theme];

const filterAccessibleModules = (menuSystemAccessibleModuleList, permissionsList) => {
  if (!menuSystemAccessibleModuleList || !permissionsList) return [];
  return menuSystemAccessibleModuleList.filter((module) => {
    const matchingPermission = permissionsList.find(
      (perm) => perm.moduleId === module.id && perm.isCheck
    );
    return !!matchingPermission;
  });
};

const Topbar = ({
  title,
  count = "0",
  icon,
  color,
  showDropdown = false,
  toggleCollapsed,
  isSmallScreen,
  menuSystemAccessibleModuleList,
  rolePermissionList,
  empPermissionList,
  type,
  ...props
}) => {
  const permissionsList = (rolePermissionList && rolePermissionList.length > 0)
    ? rolePermissionList
    : empPermissionList && empPermissionList.length > 0
      ? empPermissionList
      : [];

  const accessibleModules = filterAccessibleModules(
    menuSystemAccessibleModuleList,
    permissionsList
  );

  const userType = props.authType || type

  const renderMenuWrapper = () => {
    if (userType === loginUserType.admin) {
      return (
        <div className="width-100 menuWrapper">
          {
            menuSystemAccessibleModuleList.length > 0 &&
            menuSystemAccessibleModuleList.map((item, index) => {
              const menuItemData = getMenuItemData(
                item.description,
                props.sideBarItems
              );
              return (
                <div
                  key={item.id}
                  className={`menuItem ${menuItemData.modules.some((module) =>
                    props.location.pathname.substr(1).startsWith(module)
                  )
                    ? "selectedMenuItem"
                    : ""
                    }`}
                  onClick={() => {
                    menuItemData.route && props.history.push(menuItemData.route);
                  }}
                >
                  <i className={menuItemData.icon} />
                  <span className="title">{menuItemData.title}</span>
                </div>
              );
            })}
        </div>
      );
    } else if (userType === loginUserType.superadmin) {
      return (
        <div className="width-100 menuWrapper">
          {superadminDashboard &&
            superadminDashboard.length > 0 &&
            superadminDashboard.map((item, index) => {
              const menuItemData = getMenuItemData(
                item.moduleDescription,
                props.sideBarItems
              );
              return (
                <div
                  key={index}
                  className={`menuItem ${menuItemData &&
                    menuItemData.modules.some((module) =>
                      props.location.pathname.substr(1).startsWith(module)
                    )
                    ? "selectedMenuItem"
                    : ""
                    }`}
                  onClick={() => {
                    menuItemData.route &&
                      props.history.push(menuItemData.route);
                  }}
                >
                  {menuItemData && (
                    <>
                      <i className={menuItemData.icon} />
                      <span className="title">{menuItemData.title}</span>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      );
    } else if (userType === loginUserType.employee) {
      return (
        <div className="width-100 menuWrapper">
          {accessibleModules.map((module) => {
            const menuItemData = getMenuItemData(
              module.description,
              props.sideBarItems
            );

            if (!menuItemData) {
              return null;
            }

            return (
              <div
                key={module.id}
                className={`menuItem ${menuItemData.modules.some((mod) =>
                  props.location.pathname.substr(1).startsWith(mod)
                )
                  ? "selectedMenuItem"
                  : ""
                  }`}
                onClick={() => {
                  menuItemData.route && props.history.push(menuItemData.route);
                }}
              >
                <i className={menuItemData.icon} />
                <span className="title">{menuItemData.title}</span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div>No valid user type detected</div>;
    }
  };


  return (
    <TopbarWrapper style={{ width: "100%" }} isSmallScreen={isSmallScreen}>
      <Header className="customHeader">
        <div className="logoWrapper display-flex align-center gap-10px">
          {isSmallScreen && (
            <FontAwesomeIcon
              icon={faBars}
              style={{
                height: "20px",
                width: "20px",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={toggleCollapsed}
            />
          )}
          <img src={logo} alt="logo" className="company-logo" />
        </div>
        {!isSmallScreen && (
          <div className="flex-1 height-100">
            <Scrollbars
              style={{ height: "94px" }}
              renderView={() => (
                <div
                  style={{
                    position: "absolute",
                    inset: "0px",
                    overflow: "scroll",
                    marginRight: "-6px",
                    marginBottom: "-10px",
                  }}
                />
              )}
              renderTrackHorizontal={() => (
                <div
                  style={{
                    position: "absolute",
                    height: "6px",
                    right: "2px",
                    bottom: "-2px",
                    left: "2px",
                    borderRadius: "0px",
                  }}
                />
              )}
              renderThumbHorizontal={() => (
                <div
                  style={{ background: "rgba(255,255,255,0.6)", height: "4px" }}
                />
              )}
            >
              {renderMenuWrapper()}
            </Scrollbars>
          </div>
        )}
        <div
          className="display-flex justify-end align-center gap-10px plr-20px"
        // onClick={() => this.setState({ selectedItem: "user" })}
        >
          <TopbarUser />
        </div>
      </Header>
      {/* <Header className={"isomorphicTopbar"}>
        <div className="isoLeft">
          <button
            className={"triggerBtn"}
            style={{ color: customizedTheme.textColor }}
            onClick={toggleCollapsed}
          />
          <h2 className="ma-start-10px">{props.companyName}</h2>
        </div>

        <ul className="isoRight">
          <li
            onClick={() => this.setState({ selectedItem: "user" })}
            className="isoUser"
          >
            <TopbarUser />
          </li>
        </ul>
      </Header> */}
    </TopbarWrapper>
  );
};

export default withRouter(
  connect((state) => ({
    ...state.App.toJS(),
    authType: state.Auth.type,
    menuSystemAccessibleModuleList: state.Auth.menuSystemAccessibleModuleList,
    rolePermissionList: state.Auth.rolePermissionList,
    empPermissionList: state.Auth.empPermissionList,
    sideBarItems: state.Auth.sideBarItems,
  }))(Topbar)
);
