import actions from "./actions";

const initState = {
  ingredientId: "",
  ingredientName: "",
  usdaObject: {},
  orderIng: 0,
  orderToProcess: "",
  recipe: {},
  customer: {},
  inspection: {},
  stepForm: [],
  dividerStepForm: [],
  socketData: {},
  msgUserList: [],
  selectedUser: {},
  chatList: {},
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_INGREDIENT:
      return {
        ...state,
        ...action.data,
      };
    case actions.SEARCH_USDA:
      return {
        ...state,
        usdaObject: action.data,
      };
    case actions.SET_ORDER_ING_ID:
      return {
        ...state,
        orderIng: action.data,
      };
    case actions.SET_ORDERS:
      return {
        ...state,
        orderToProcess: action.data,
      };
    case actions.SET_ITEM:
      return {
        ...state,
        unit: action.data,
      };

    case actions.SET_RECIPE:
      return { ...state, recipe: action.data };

    case actions.SET_CUSTOMER:
      return { ...state, customer: action.data };

    case actions.SET_INSPECTION: {
      return { ...state, inspection: action.data };
    }
    case actions.SET_STEP_FORM: {
      return { ...state, stepForm: action.data };
    }
    case actions.SET_DIVIDER_STEP_FORM: {
      return { ...state, dividerStepForm: action.data };
    }
    case actions.SET_SOCKET: {
      return { ...state, socketData: action.data };
    }
    case actions.SET_MSG_USER_LIST: {
      return { ...state, msgUserList: action.data };
    }
    case actions.SET_CHAT_LIST: {
      return { ...state, chatList: action.data };
    }
    case actions.SET_SELECTED_USER: {
      return { ...state, selectedUser: action.data };
    }
    default:
      return state;
  }
}
